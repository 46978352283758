import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

const Calendar = ({ paymentsData }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

  const days = Array.from({ length: daysInMonth }, (_, index) => {
    const date = new Date(currentYear, currentMonth, index + 1);
    const formattedDate = date.toISOString().split("T")[0];
    const paymentsInfo = paymentsData[formattedDate] || "No hay pagos";

    return {
      date,
      info: paymentsInfo,
    };
  });

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {/* Header */}
      <div className="px-4 py-2 bg-gray-100 border-b">
        <h2 className="text-lg font-semibold text-gray-700">
          {currentDate.toLocaleString("es-ES", { month: "long", year: "numeric" })}
        </h2>
      </div>

      {/* Days of the Week */}
      <div className="grid grid-cols-7 gap-1 text-center text-sm font-semibold text-gray-600 border-b">
        {daysOfWeek.map((day) => (
          <div key={day} className="py-2 uppercase">
            {day.slice(0, 3)}
          </div>
        ))}
      </div>

      {/* Days of the Month */}
      <div className="grid grid-cols-7 gap-1">
        {/* Empty spaces for the first row */}
        {Array.from({ length: firstDayOfMonth }).map((_, index) => (
          <div key={index} className="py-2"></div>
        ))}

        {days.map(({ date, info }, index) => (
          <div
            key={index}
            className={`p-2 h-24 flex flex-col justify-between rounded border ${
              date.toDateString() === currentDate.toDateString()
                ? "bg-blue-100 border-blue-400"
                : info !== "No hay pagos"
                ? "bg-green-100 border-green-400"
                : "bg-gray-50 border-gray-200"
            }`}
          >
            <div className="text-right text-xs font-semibold text-gray-500">
              {date.toDateString() === currentDate.toDateString() ? "Hoy" : date.getDate()}
            </div>
            <div className="text-sm text-gray-700">
              {typeof info === "string"
                ? info
                : `Pagos: ${info.length} (${info.reduce((sum, p) => sum + p.amount, 0)} MXN)`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AdminHome = () => {
  const [paymentsData, setPaymentsData] = useState({});
  const [stats, setStats] = useState({
    todayPayments: 0,
    monthPayments: 0,
    activeBusinesses: 0,
    inactiveBusinesses: 0,
  });

  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const startOfToday = new Date(today.setHours(0, 0, 0, 0));
        const endOfToday = new Date(today.setHours(23, 59, 59, 999));
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        const paymentsSnapshot = await getDocs(collection(db, "cobros"));
        const businessesSnapshot = await getDocs(collection(db, "negocios"));

        let todayPayments = 0;
        let monthPayments = 0;
        let activeBusinesses = 0;
        let inactiveBusinesses = 0;

        const payments = {};
        paymentsSnapshot.forEach((doc) => {
          const data = doc.data();
          const date = data.date.split("T")[0]; // Formato YYYY-MM-DD
          const amount = parseFloat(data.amount || 0);
          const paymentDate = new Date(data.date);

          if (paymentDate >= startOfToday && paymentDate <= endOfToday) {
            todayPayments += amount;
          }

          if (paymentDate >= startOfMonth) {
            monthPayments += amount;
          }

          if (!payments[date]) {
            payments[date] = [];
          }
          payments[date].push({ ...data, amount });
        });

        businessesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === "activo") {
            activeBusinesses += 1;
          } else if (data.status === "inactivo") {
            inactiveBusinesses += 1;
          }
        });

        setPaymentsData(payments);
        setStats({ todayPayments, monthPayments, activeBusinesses, inactiveBusinesses });
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, [db]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Panel de Estadísticas</h1>
      {/* Tarjetas de estadísticas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        <div className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition-shadow">
          <div>
            <h2 className="text-xl font-semibold">Cobros de Hoy</h2>
            <p className="text-gray-600 text-lg mt-2">
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(stats.todayPayments)}
            </p>
          </div>
          <div className="text-4xl text-blue-500">📅</div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition-shadow">
          <div>
            <h2 className="text-xl font-semibold">Cobros del Mes</h2>
            <p className="text-gray-600 text-lg mt-2">
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(stats.monthPayments)}
            </p>
          </div>
          <div className="text-4xl text-green-500">💵</div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition-shadow">
          <div>
            <h2 className="text-xl font-semibold">Comercios Activos</h2>
            <p className="text-gray-600 text-lg mt-2">{stats.activeBusinesses}</p>
          </div>
          <div className="text-4xl text-green-500">✔️</div>
        </div>

        <div className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition-shadow">
          <div>
            <h2 className="text-xl font-semibold">Comercios Inactivos</h2>
            <p className="text-gray-600 text-lg mt-2">{stats.inactiveBusinesses}</p>
          </div>
          <div className="text-4xl text-red-500">❌</div>
        </div>
      </div>

      <Calendar paymentsData={paymentsData} />
    </div>
  );
};

export default AdminHome;
