import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  addDoc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import bgApp from "../assets/bg-app.png";
import logo from "../assets/logo.png";
import { FaArrowLeft } from "react-icons/fa";
import { getAuth } from "firebase/auth";
import errorImage from "../assets/error-negocio.png";
import cargandoNegocio from "../assets/cargandoNegocio.gif";
import { FaWhatsapp } from "react-icons/fa6";
import { IoQrCode } from "react-icons/io5";


const SingleBusinessPage = () => {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [agentLocation, setAgentLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasPaidToday, setHasPaidToday] = useState(false);
  const [showCamera, setShowCamera] = useState(false); // Mostrar la cámara
  const [capturingPhoto, setCapturingPhoto] = useState(false);
  const videoRef = useRef(null);

  const db = getFirestore();
  const storage = getStorage();

  const downloadQR = (qrUrl, name) => {
    const link = document.createElement("a");
    link.href = qrUrl; // URL del QR desde Firebase Storage
    link.download = `${name}_QR.png`; // Nombre del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Manejar el cobro
  const handlePayment = async () => {
    if (!business || !agentLocation) return;

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const timestamp = new Date().toISOString();

      const transactionData = {
        businessId: business.id,
        agentId: user.uid,
        date: timestamp,
        amount: business.quota || 0,
        location: `Lat: ${agentLocation.latitude}, Lng: ${agentLocation.longitude}`,
      };

      const docRef = await addDoc(collection(db, "cobros"), transactionData);
      navigate("/recibo", { state: { transaction: { id: docRef.id, ...transactionData } } });
    } catch (error) {
      console.error("Error al registrar el cobro:", error);
    }
  };

  // Abrir la cámara
// Abrir la cámara
const handleNotFound = async () => {
    setShowCamera(true);
  
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      const backCamera = videoDevices.find((device) => device.label.toLowerCase().includes("back"));
  
      const constraints = {
        video: backCamera
          ? { deviceId: { exact: backCamera.deviceId } } // Usar la cámara trasera si está disponible
          : { facingMode: "environment" }, // Fallback a facingMode
      };
  
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
  
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error al activar la cámara:", error);
    }
  };
  

  // Capturar la foto
  const capturePhoto = async () => {
    if (!videoRef.current || !business || !agentLocation) return;

    const canvas = document.createElement("canvas");
    canvas.width = 640;
    canvas.height = 480;
    const context = canvas.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // Detener la cámara
    const stream = videoRef.current.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }

    const photoBlob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
    const storageRef = ref(storage, `reportes/${business.id}/${Date.now()}.jpg`);
    await uploadBytes(storageRef, photoBlob);

    const photoURL = await getDownloadURL(storageRef);

    const auth = getAuth();
    const user = auth.currentUser;
    const timestamp = new Date().toISOString();

    // Crear reporte en Firestore
    const reportData = {
      businessId: business.id,
      agentId: user.uid,
      date: timestamp,
      location: `Lat: ${agentLocation.latitude}, Lng: ${agentLocation.longitude}`,
      photoURL,
    };

    await addDoc(collection(db, "reportes"), reportData);

    // Incrementar reportes en el negocio
    const updatedReports = (business.reportes || 0) + 1;
    const updatedStatus = updatedReports >= 5 ? "inactivo" : business.status;

    await updateDoc(doc(db, "negocios", business.id), {
      reportes: updatedReports,
      status: updatedStatus,
    });

    setBusiness((prev) => ({
      ...prev,
      reportes: updatedReports,
      status: updatedStatus,
    }));

    setShowCamera(false);

    if (updatedReports >= 5) {
      alert("El negocio ahora está inactivo debido a múltiples reportes.");
    }
  };

  // Verificar si ya se realizó el pago hoy
  const checkPaymentStatus = async () => {
    if (!business) return;

    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).toISOString();
    const endOfDay = new Date(today.setHours(23, 59, 59, 999)).toISOString();

    const paymentsQuery = query(
      collection(db, "cobros"),
      where("businessId", "==", business.id),
      where("date", ">=", startOfDay),
      where("date", "<=", endOfDay)
    );

    const querySnapshot = await getDocs(paymentsQuery);
    setHasPaidToday(!querySnapshot.empty);
  };

  // Cargar los datos del negocio desde Firestore
  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const businessDoc = await getDoc(doc(db, "negocios", businessId));
        if (businessDoc.exists()) {
          setBusiness({ id: businessDoc.id, ...businessDoc.data() });
        } else {
          console.error("Negocio no encontrado.");
        }
      } catch (error) {
        console.error("Error al obtener el negocio:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, [businessId, db]);

  // Obtener la ubicación del agente
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setAgentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error al obtener la ubicación del agente:", error);
        }
      );
    }
  }, []);

  // Verificar el estado del pago al cargar el negocio
  useEffect(() => {
    if (business) {
      checkPaymentStatus();
    }
  }, [business]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <img src={cargandoNegocio} alt="Cargando negocio..." />
      </div>
    );
  }

  if (!business) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <img
          src={errorImage}
          alt="Error negocio no encontrado"
          className="w-1/2 max-w-sm mb-6"
        />
        <h1 className="text-2xl font-bold text-[#861E3D] mb-4 text-center">
          Negocio no encontrado
        </h1>
        <button
          onClick={() => navigate("/cobrador")}
          className="px-6 py-3 bg-[#861E3D] text-white rounded-lg shadow hover:bg-[#701730] transition duration-300"
        >
          Regresar al inicio
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${bgApp})` }}>
      <header className="w-full p-4 flex items-center relative bg-opacity-80">
        <button
          onClick={() => navigate("/cobrador")}
          className="bg-[#861E3D] text-white p-3 rounded-full hover:bg-[#701730] flex items-center justify-center absolute left-4"
        >
          <FaArrowLeft size={20} />
        </button>
        <div className="flex-1 flex justify-center">
          <img src={logo} alt="Logo" style={{ width: "150px" }} className="h-auto" />
        </div>
      </header>

      {/* Modal para la cámara */}
     {/* Modal para la cámara */}
{showCamera && (
  <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
      <h2 className="text-lg font-bold mb-4 text-center">Capturar Foto</h2>
      <video
        ref={videoRef}
        className="w-full h-auto rounded-lg mb-4"
        playsInline
      ></video>
      <div className="flex justify-between">
        <button
          onClick={() => setShowCamera(false)}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Cancelar
        </button>
        <button
          onClick={capturePhoto}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Capturar
        </button>
      </div>
    </div>
  </div>
)}

      {/* Información del negocio */}
      <div className="flex flex-col items-center mt-8 px-4">
        <h1 className="text-3xl font-bold text-[#861E3D] mb-2">{business.name}</h1>
        <p className="text-xl text-gray-600 mb-4">{business.owner}</p>
        
        <div className="flex items-center justify-between mb-6">
  {/* Teléfono con ícono de WhatsApp */}
  <div className="bg-[#861E3D] flex items-center gap-2  text-white px-4 py-2 rounded-lg">
  <FaWhatsapp /><span>{business.phone} </span>
  </div>

  {/* Botón de descarga del QR */}
  {business.qrUrl && (
    <div>
    <button style={{ marginLeft:"20px"}}
      onClick={() => downloadQR(business.qrUrl, business.name)}
      className="bg-[#861E3D] text-white rounded-full w-12 h-12 flex items-center justify-center shadow-lg hover:bg-[#701730]"
    >
     <IoQrCode />

    </button>
    </div>
  )}
</div>


        {business.status === "inactivo" && (
          <p className="text-red-500 font-bold mb-4">Este negocio está inactivo.</p>
        )}

<button
  className={`w-3/4 py-4 rounded-lg text-xl mb-4 ${
    business.status === "inactivo"
      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
      : hasPaidToday
      ? "bg-green-500 text-white cursor-not-allowed"
      : "bg-[#861E3D] text-white"
  }`}
  disabled={business.status === "inactivo" || hasPaidToday}
  onClick={handlePayment}
>
  {hasPaidToday ? "PAGADO" : `COBRAR $${business.quota || "0.00"}`}
</button>


        <button
          className={`w-3/4 py-4 rounded-lg text-xl ${
            business.status === "inactivo"
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-[#c7a26d] text-white"
          }`}
          disabled={business.status === "inactivo"}
          onClick={handleNotFound}
        >
          No encontrado
        </button>
      </div>
    </div>
  );
};

export default SingleBusinessPage;
