import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import logo from "../assets/logo.png"; // Asegúrate de que el logo esté en esta ruta
import { FaCheckCircle } from "react-icons/fa";
import { toPng } from "html-to-image";

import { useNavigate } from "react-router-dom";


const PaymentReceipt = () => {
  const location = useLocation();
  const { transaction } = location.state || {};
  const receiptRef = useRef(null);
 
  const navigate = useNavigate();


  if (!transaction) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <p className="text-lg text-gray-600">Datos de la transacción no disponibles.</p>
      </div>
    );
  }
  

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Comprobante de Pago",
          text: `Pago exitoso de $${transaction.amount} realizado el ${new Date(
            transaction.date
          ).toLocaleString()}.\nCódigo de transacción: ${transaction.id}\nPagado por: ${transaction.businessName}`,
        })
        .catch((error) => console.error("Error al compartir:", error));
    } else {
      alert("La funcionalidad de compartir no está disponible en este navegador.");
    }
  };

  const handleDownloadImage = async () => {
    if (receiptRef.current) {
      try {
        const dataUrl = await toPng(receiptRef.current);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `Recibo_${transaction.id}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error al generar la imagen:", error);
      }
    }
  };

  return (
    <div
  className="flex flex-col items-center justify-center min-h-screen"
  style={{ backgroundColor: "#861E3D" }}
>
  {/* Botón para volver al inicio */}
  <button
    onClick={() => navigate("/cobrador")} // Reemplaza "/" con la ruta correspondiente a tu página de inicio
    className="absolute top-4 left-4 bg-gray-300 text-gray-700 px-4 py-2 rounded-lg text-sm font-semibold hover:bg-gray-400 transition"
  >
    Volver al inicio
  </button>

  <div
    ref={receiptRef} // Referencia para generar la imagen
    className="relative bg-white rounded-lg p-6 pt-16 w-[90%] max-w-md text-center shadow-lg"
  >
    {/* Icono de éxito con contorno blanco */}
    <div className="absolute -top-12 left-1/2 transform -translate-x-1/2 bg-white p-3 rounded-full shadow-lg">
      <FaCheckCircle size={60} className="text-green-500" />
    </div>

    {/* Logo */}
    <img src={logo} alt="Logo" className="w-32 mx-auto mb-6 mt-4" />

    {/* Mensaje de éxito */}
    <h1 className="text-2xl font-bold mb-2 text-[#861E3D]">Pago exitoso.</h1>

    {/* Monto */}
    <p className="text-lg mb-1 text-gray-700">Monto:</p>
    <h2 className="text-4xl font-extrabold mb-4 text-black">${transaction.amount}</h2>

    {/* Fecha */}
    <p className="text-sm mb-2 text-gray-500">
      {new Date(transaction.date).toLocaleString()}
    </p>

    {/* Nombre del negocio */}
    <p className="text-lg mb-4 text-gray-700 font-semibold">
      Pagado por: <span className="text-[#861E3D]">{transaction.businessName}</span>
    </p>

    {/* Código de transacción */}
    <p className="text-sm mb-6 font-semibold text-gray-700">
      Código de transacción:{" "}
      <span className="text-[#861E3D]">{transaction.id}</span>
    </p>
  </div>

  {/* Botón dorado para descargar comprobante */}
  <button
    onClick={handleDownloadImage}
    className="bg-[#c7a26d] text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-[#a68b5c] transition mt-6"
  >
    Descargar comprobante
  </button>
</div>

  );
};

export default PaymentReceipt;
