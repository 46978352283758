import React, { useState, useEffect, useCallback } from "react";
import { getFirestore, collection, addDoc, getDocs, updateDoc,  onSnapshot  } from "firebase/firestore";
import BusinessForm from "../components/BusinessForm";
import { FaTimes } from "react-icons/fa"; // Importar el icono de cierre

import { getAuth } from "firebase/auth";
import { QRCodeCanvas } from "qrcode.react";
import { getStorage, ref, uploadString, getDownloadURL} from "firebase/storage";



const auth = getAuth();
const user = auth.currentUser;
const userId = user ? user.uid : null;


const BusinessesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [newBusinesses, setNewBusinesses] = useState([]);
  const [newBusiness, setNewBusiness] = useState({
    name: "",
    location: "",
    phone: "",
    owner: "",
    type: "",
    quota: 0, // Nuevo campo
    creatorId: userId || "unknown",
    createdAt: null,
    status: "activo",
    qrUrl: "",
  });
  
  
  

  const db = getFirestore();

  const downloadQR = (qrUrl, name) => {
    const link = document.createElement("a");
    link.href = qrUrl; // URL del QR desde Firebase Storage
    link.download = `${name}_QR.png`; // Nombre del archivo descargado
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const fetchBusinesses = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "negocios"));
      const fetchedBusinesses = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewBusinesses(fetchedBusinesses);
    } catch (error) {
      console.error("Error al obtener negocios:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchBusinesses();
  }, [fetchBusinesses]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "negocios"),
      (snapshot) => {
        const businesses = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNewBusinesses(businesses);
      },
      (error) => {
        console.error("Error al obtener datos en tiempo real:", error);
      }
    );
  
    // Limpiar la suscripción al desmontar el componente
    return () => unsubscribe();
  }, [db]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBusiness({ ...newBusiness, [name]: value });
  };

  const handleNextStep = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const detectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setNewBusiness({
            ...newBusiness,
            location: `Lat: ${latitude}, Lng: ${longitude}`,
          });
        },
        (error) => {
          console.error("Error al obtener ubicación:", error);
        }
      );
    } else {
      alert("La geolocalización no está soportada en este navegador.");
    }
  };

  const handleFormSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const userId = user ? user.uid : "unknown";
  
      const timestamp = new Date().toISOString();
  
      // Validar días de apertura
      if (!newBusiness.schedule?.days || newBusiness.schedule.days.length === 0) {
        alert("Por favor selecciona al menos un día de apertura.");
        return;
      }
  
      const businessData = {
        ...newBusiness,
        createdAt: timestamp,
        creatorId: userId,
      };
  
      // Crear negocio en Firestore
      const docRef = await addDoc(collection(db, "negocios"), businessData);
  
      // Crear un canvas dinámico para generar el QR
      const canvas = document.createElement("canvas");
      canvas.width = 300; // Ancho del canvas
      canvas.height = 400; // Altura extendida para incluir texto debajo del QR
      const context = canvas.getContext("2d");
  
      // Generar el QR en el canvas
      const QRCode = require("qrcode");
      await QRCode.toCanvas(canvas, docRef.id, { width: 300 });
  
      // Reducir tamaño del logotipo
      const logoImage = new Image();
      logoImage.src = require("../assets/logoQr.png"); // Ruta al logotipo
  
      // Esperar la carga del logotipo
      logoImage.onload = async () => {
        const logoSize = 40; // Tamaño reducido del logotipo
        const logoX = (canvas.width - logoSize) / 2; // Centrar horizontalmente
        const logoY = (300 - logoSize) / 2; // Centrar en el área del QR (300px de alto)
  
        // Dibujar fondo blanco para el logo
        context.fillStyle = "white";
        context.fillRect(logoX, logoY, logoSize, logoSize);
  
        // Dibujar el logotipo
        context.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
  
        // Verificar si el nombre del negocio existe
        const businessName = newBusiness.name?.trim() || "Nombre no disponible";
  
        // Configuración para el texto del nombre del negocio
        context.fillStyle = "#861E3D"; // Color del texto (coincidiendo con el ejemplo)
        context.font = "bold 18px Arial"; // Fuente del texto
        context.textAlign = "center"; // Centrar el texto horizontalmente
  
        // Dibujar el texto debajo del QR
        context.fillText(
          businessName, // Nombre del negocio
          canvas.width / 2, // Posición X (centrado)
          290 // Posición Y (debajo del QR)
        );
  
        // Convertir el canvas a una imagen PNG en base64
        const qrBase64 = canvas.toDataURL("image/png");
  
        // Subir QR a Firebase Storage
        const storage = getStorage();
        const qrRef = ref(storage, `qr_codes/${docRef.id}.png`);
        await uploadString(qrRef, qrBase64.split(",")[1], "base64");
  
        // Obtener la URL del QR
        const qrUrl = await getDownloadURL(qrRef);
  
        // Actualizar negocio con la URL del QR en Firestore
        await updateDoc(docRef, { qrUrl });
  
        // Actualizar estado inmediatamente después de obtener la URL
        setNewBusiness((prev) => ({
          ...prev,
          qrUrl,
        }));
  
        // Avanzar al paso 3
        setCurrentStep(3);
      };
    } catch (error) {
      console.error("Error al registrar negocio:", error);
    }
  };
  
 
  return (
    <div className="container mx-auto p-4 min-h-screen overflow-auto">
  <h1 className="text-2xl font-bold mb-4">Lista de Negocios</h1>
  <button
    className="bg-blue-500 text-white px-4 py-2 rounded mb-4 hover:bg-blue-600"
    onClick={() => setIsModalOpen(true)}
  >
    Registrar Negocio
  </button>
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white shadow-md rounded-lg">
    <thead>
  <tr className="bg-gray-200 text-gray-700 uppercase text-sm leading-normal">
    <th className="py-3 px-6 text-left">Nombre</th>
    <th className="py-3 px-6 text-left">Ubicación</th>
    <th className="py-3 px-6 text-left">Teléfono</th>
    <th className="py-3 px-6 text-left">Propietario</th>
    <th className="py-3 px-6 text-left">Tipo</th>
    <th className="py-3 px-6 text-left">Cuota</th> {/* Nueva columna */}
    <th className="py-3 px-6 text-left">Estado</th>
    <th className="py-3 px-6 text-center">QR</th>
  </tr>
</thead>

<tbody className="text-gray-600 text-sm font-light">
  {newBusinesses.length > 0 ? (
    newBusinesses.map((business, index) => (
      <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
        <td className="py-3 px-6 text-left whitespace-nowrap">{business.name}</td>
        <td className="py-3 px-6 text-left">
          {business.address || "No disponible"}
        </td>
        <td className="py-3 px-6 text-left">{business.phone}</td>
        <td className="py-3 px-6 text-left">{business.owner}</td>
        <td className="py-3 px-6 text-left">{business.type}</td>
        <td className="py-3 px-6 text-left">${business.quota || "0.00"}</td> {/* Mostrar cuota */}
        <td className="py-3 px-6 text-left">{business.status}</td>
        <td className="py-3 px-6 text-center">
          {business.qrUrl ? (
            <button
              onClick={() => downloadQR(business.qrUrl, business.name)}
              className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
            >
              Descargar QR
            </button>
          ) : (
            "No disponible"
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" className="text-center py-3 text-gray-500 italic">
        No hay negocios disponibles.
      </td>
    </tr>
  )}
</tbody>

    </table>
  </div>

  {isModalOpen && (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
      onClick={(e) => {
        if (e.target.classList.contains("bg-gray-600")) {
          setIsModalOpen(false); // Cerrar modal al hacer clic fuera
        }
      }}
    >
      <div className="bg-white p-6 rounded shadow-md w-[800px] relative ">
        
        <button
          type="button"
          onClick={() => setIsModalOpen(false)} // Cerrar modal al hacer clic en el icono
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={20} />
        </button>
        <BusinessForm
          newBusiness={newBusiness}
          setNewBusiness={setNewBusiness}
          handleInputChange={handleInputChange}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          detectLocation={detectLocation}
          handleFormSubmit={handleFormSubmit}
          currentStep={currentStep}
          handleCancel={() => setIsModalOpen(false)} // Pasar función para cancelar
        />
      </div>
    </div>
  )}
</div>

  );
};

export default BusinessesPage;
