import React, { useState, useEffect } from "react";
import { getFirestore, collection, onSnapshot, addDoc } from "firebase/firestore";
import { Html5Qrcode } from "html5-qrcode";
import logo from "../assets/logo.png";
import bg from "../assets/bg-app.png";
import loadingGif from "../assets/carga-gps.gif";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMoreVert, MdQrCodeScanner } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BusinessForm from "../components/BusinessForm";
import { FaTimes } from "react-icons/fa";
import { MdOutlineAddBusiness } from "react-icons/md";


const AgentDashboard = () => {
  const [agentLocation, setAgentLocation] = useState(null);
  const [address, setAddress] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const [nearbyBusinesses, setNearbyBusinesses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showScanner, setShowScanner] = useState(false);
  const [html5QrCode, setHtml5QrCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [newBusiness, setNewBusiness] = useState({
    name: "",
    location: "",
    phone: "",
    owner: "",
    type: "",
    quota: 0,
    creatorId: "unknown",
    createdAt: null,
    status: "activo",
    qrUrl: "",
  });

  const navigate = useNavigate();

  const RADIUS = 0.04;
  const db = getFirestore();

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const fetchAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const data = response.data.address;
      const street = data.road || "Calle no disponible";
      const neighborhood =
        data.suburb ||
        data.neighbourhood ||
        data.city_district ||
        "Colonia no disponible";

      setAddress(`${street}, ${neighborhood}`);
    } catch (error) {
      console.error("Error al obtener la dirección:", error);
      setAddress("Dirección no disponible");
    }
  };

  const fetchAgentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setAgentLocation({ latitude, longitude });
          fetchAddressFromCoordinates(latitude, longitude);
          setLoading(false);
        },
        (err) => {
          setError("No se pudo obtener la ubicación. Habilita los permisos de ubicación.");
          setLoading(false);
        }
      );
    } else {
      setError("La geolocalización no está soportada en este navegador.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "negocios"), (snapshot) => {
      const fetchedBusinesses = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBusinesses(fetchedBusinesses);
    });

    return () => unsubscribe();
  }, [db]);

  useEffect(() => {
    if (agentLocation && businesses.length > 0) {
      const filtered = businesses.filter((business) => {
        if (business.location) {
          const [lat, lng] = business.location
            .replace("Lat: ", "")
            .replace("Lng: ", "")
            .split(", ")
            .map(Number);
          const distance = calculateDistance(
            agentLocation.latitude,
            agentLocation.longitude,
            lat,
            lng
          );
          return distance <= RADIUS;
        }
        return false;
      });
      setNearbyBusinesses(filtered);
    }
  }, [agentLocation, businesses]);

  useEffect(() => {
    fetchAgentLocation();
  }, []);

  useEffect(() => {
    if (showScanner) {
      const qrCode = new Html5Qrcode("reader");
      setHtml5QrCode(qrCode);

      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            qrCode.start(
              { facingMode: "environment" },
              {
                fps: 10,
                qrbox: 250,
              },
              (decodedText) => {
                setShowScanner(false);
                if (qrCode.isScanning) {
                  qrCode.stop().then(() => qrCode.clear()).catch(console.error);
                }
                navigate(`/negocio/${decodedText}`);
              },
              (error) => {
                console.warn(`Escaneo fallido: ${error}`);
              }
            );
          }
        })
        .catch((err) => console.error("No se encontraron cámaras:", err));

      return () => {
        if (qrCode && qrCode.isScanning) {
          qrCode
            .stop()
            .then(() => qrCode.clear())
            .catch(console.error);
        }
      };
    }
  }, [showScanner, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBusiness({ ...newBusiness, [name]: value });
  };

  const handleNextStep = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const detectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setNewBusiness({
            ...newBusiness,
            location: `Lat: ${latitude}, Lng: ${longitude}`,
          });
        },
        (error) => {
          console.error("Error al obtener ubicación:", error);
        }
      );
    } else {
      alert("La geolocalización no está soportada en este navegador.");
    }
  };

  const handleFormSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    try {
      const timestamp = new Date().toISOString();

      const businessData = {
        ...newBusiness,
        createdAt: timestamp,
      };

      await addDoc(collection(db, "negocios"), businessData);

      setIsModalOpen(false);
      setNewBusiness({
        name: "",
        location: "",
        phone: "",
        owner: "",
        type: "",
        quota: 0,
        creatorId: "unknown",
        createdAt: null,
        status: "activo",
        qrUrl: "",
      });
    } catch (error) {
      console.error("Error al registrar negocio:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <img src={loadingGif} alt="Cargando ubicación..." className="w-32 h-32" />
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex flex-col items-center text-white"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Header */}
      <header className="w-full p-4 flex items-center justify-between bg-opacity-80 relative">
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-[#861E3D] text-white p-3 rounded-full hover:bg-[#701730] flex items-center justify-center"
        >
          <MdOutlineAddBusiness size={24} />
        </button>

        <div className="absolute left-1/2 transform -translate-x-1/2">
          <img src={logo} alt="Logo" style={{ width: "150px" }} className="h-auto" />
        </div>

        <button
          onClick={() => setShowScanner(true)}
          className="bg-[#861E3D] text-white p-3 rounded-full hover:bg-[#701730] flex items-center justify-center"
        >
          <MdQrCodeScanner size={24} />
        </button>
      </header>

      {/* Contenido principal */}
      <div className="flex-1 w-full p-4">
        <h1 className="text-xl font-bold text-center mb-6 text-black">Negocios en tu ubicación</h1>
        {error && <p className="text-red-500 text-center">{error}</p>}
        {agentLocation ? (
          <div>
            <p className="mb-4 text-center text-black flex items-center justify-center">
              <FaMapMarkerAlt className="mr-2 text-red-500 text-lg" />
              {address || "Obteniendo dirección..."}
            </p>
            {nearbyBusinesses.length > 0 ? (
              <ul className="space-y-4">
                {nearbyBusinesses.map((business) => (
                  <li
                    key={business.id}
                    className="bg-white shadow-lg rounded-lg p-4 flex items-center"
                  >
                    <Link
                      to={`/negocio/${business.id}`}
                      className="flex items-center flex-1 no-underline text-inherit"
                    >
                      <div className="w-12 h-12 flex items-center justify-center bg-[#701730] text-white rounded-full mr-4 text-lg font-semibold">
                        {business.name
                          .split(" ")
                          .map((word) => word.charAt(0))
                          .join("")}
                      </div>
                      <div className="flex-1">
                        <h2 className="text-lg font-bold text-[#701730]">{business.name}</h2>
                        <p className="text-sm text-gray-600">{business.owner}</p>
                      </div>
                    </Link>
                    <button className="text-gray-400 hover:text-gray-600">
                      <MdOutlineMoreVert size={"30px"} />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-center">No hay negocios cercanos dentro de 100 metros.</p>
            )}
          </div>
        ) : (
          <p className="text-center">Obteniendo tu ubicación...</p>
        )}
      </div>

      {/* Modal para el escáner QR */}
      {showScanner && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-black w-full max-w-md">
            <h2 className="text-lg font-bold mb-4 text-center">Escanea el código QR</h2>
            <div id="reader" className="w-full"></div>
            <button
              onClick={() => setShowScanner(false)}
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 w-full"
            >
              Cancelar
            </button>
          </div>
        </div>
      )}

     {/* Modal para registrar negocio */}
{isModalOpen && (
  <div
    className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
    onClick={(e) => {
      // Cierra el modal si se hace clic fuera del contenido
      if (e.target === e.currentTarget) {
        setIsModalOpen(false);
      }
    }}
  >
    <div className="bg-white p-6 rounded shadow-md w-[800px] relative">
      <button
        type="button"
        onClick={() => setIsModalOpen(false)}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
      >
        <FaTimes size={20} />
      </button>
      <BusinessForm
        newBusiness={newBusiness}
        setNewBusiness={setNewBusiness}
        handleInputChange={handleInputChange}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
        detectLocation={detectLocation}
        handleFormSubmit={handleFormSubmit}
        currentStep={currentStep}
        handleCancel={() => setIsModalOpen(false)}
        additionalStyles={{
          input: "text-black", // Aplica el color negro a los campos de entrada
        }}
      />
    </div>
  </div>
)}

    </div>
  );
};

export default AgentDashboard;
